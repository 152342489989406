<script>
const divisi = "";
import Vuex from "vuex";
// import { mapGetters } from "vuex";
export default {
  name: "nav",
  data() {
    return {
      AllSideBarItem: [
        // {
        //   _name: "CSidebarNavItem",
        //   name: "Dashboard HCMS",
        //   to: "/dashboardHcms",
        //   icon: "cil-speedometer",
        //   lang: "en",
        // },
        {
          _name: "CSidebarNavItem",
          name: "Beranda HCMS",
          to: "/dashboardHcms",
          icon: "cil-speedometer",
          lang: "id",
        },

        // {
        //   _name: "CSidebarNavDropdown",
        //   name: " Master Data",
        //   icon: "cil-layers",
        //   lang: "en",
        //   items: [
        //     {
        //       name: "Insurance",
        //       to: "/asuransi",
        //     },

        //     // {
        //     //   name: "Absence",
        //     //   to: "/cuti",
        //     // },

        //     {
        //       name: "Division",
        //       to: "/divisi",
        //     },
        //     {
        //       name: "Salary",
        //       to: "/gaji",
        //     },

        //     {
        //       name: " Applicant Administration Doc",
        //       to: "/dokAdministrasi",
        //     },

        //     {
        //       name: "Management Position ",
        //       to: "/masterJabatan",
        //     },

        //     // {
        //     //   name: "Evaluation Category",
        //     //   to: "/kategoriPenilaian",
        //     // },

        //     // {
        //     //   name: "Termination Decription",
        //     //   to: "/keteranganHapus",
        //     // },

        //     // {
        //     //   name: "Absence Description",
        //     //   to: "/keteranganIjin",
        //     // },

        //     // {
        //     //   name: "Evaluation",
        //     //   to: "/penilaian",
        //     // },

        //     // {
        //     //   name: "Position",
        //     //   to: "/posisi",
        //     // },

        //     {
        //       name: "Violation",
        //       to: "/pelanggaran",
        //     },

        //     // {
        //     //   name: "Perusahaan Penempatan",
        //     //   to: "/masterPerusahaanPenempatan",
        //     // },

        //     {
        //       name: "Shift",
        //       to: "/masterShift",
        //     },

        //     {
        //       name: "Partner",
        //       to: "/masterMitraPusat",
        //     },

        //     // {
        //     //   name: "Branch",
        //     //   to: "/masterMitraPembantu",
        //     // },

        //     // {
        //     //   name: "Work Accident",
        //     //   to: "/masterKecelakaanKerja",
        //     // },

        //     {
        //       name: "Applicant Stage Selection",
        //       to: "/tahapanSeleksi",
        //     },

        //     {
        //       name: "User ",
        //       to: "/user",
        //     },
        //     {
        //       name: "Aloowance",
        //       to: "/tunjangan",
        //     },
        //     {
        //       name: "Cut",
        //       to: "/potongan",
        //     },

        //     {
        //       name: "Non-fixed Allowance",
        //       to: "/tunjanganTidakTetap",
        //     },
        //   ],
        // },

        {
          _name: "CSidebarNavDropdown",
          name: "Data Master",
          icon: "cil-layers",
          lang: "id",
          role: ["admin rekrutment", "superadmin", "admin hrd", "admin spv"],
          items: [
            {
              name: "Asuransi",
              to: "/asuransi",
            },

            // {
            //   name: "Cuti",
            //   to: "/cuti",
            // },

            {
              name: "Divisi",
              to: "/divisi",
            },

            {
              name: "Gaji",
              to: "/gaji",
            },

            {
              name: "Dok. Administrasi Pelamar",
              to: "/dokAdministrasi",
            },

            {
              name: "Jabatan",
              to: "/masterJabatan",
            },

            // {
            //   name: "Kategori Evaluasi",
            //   to: "/kategoriPenilaian",
            // },

            // {
            //   name: "Keterangan Hapus / Dipecat",
            //   to: "/keteranganHapus",
            // },

            // {
            //   name: "Penilaian",
            //   to: "/penilaian",
            // },

            // {
            //   name: "Posisi",
            //   to: "/posisi",
            // },
            {
              name: "Pelanggaran",
              to: "/pelanggaran",
            },

            // {
            //   name: "Perusahaan Penempatan",
            //   to: "/masterPerusahaanPenempatan",
            // },

            {
              name: "Shift",
              to: "/masterShift",
            },

            {
              name: "Mitra",
              to: "/masterMitra",
            },

            // {
            //   name: "Cabang",
            //   to: "/masterMitraPembantu",
            // },

            {
              name: "Tahapan Seleksi Pelamar",
              to: "/tahapanSeleksi",
            },

            {
              name: "User",
              to: "/user",
            },

            {
              name: "Tunjangan",
              to: "/tunjangan",
            },

            {
              name: "Potongan",
              to: "/potongan",
            },

            {
              name: "Tunjangan Tidak Tetap",
              to: "/tunjanganTidakTetap",
            },
          ],
        },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Job Vacancy",
        //   to: "/lowonganKerja",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        {
          _name: "CSidebarNavItem",
          name: "Lowongan Pekerjaan",
          to: "/lowonganKerja",
          icon: "cil-layers",
          lang: "id",
          role: ["admin rekrutment", "superadmin", "admin spv"],
        },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Pelamar Kerja",
        //   to: "/pelamarKerja",
        //   icon: "cil-layers",
        //   // badge: {
        //   //   color: "primary",
        //   //   text: "NEW",
        //   // },
        // },

        // {
        //   _name: "CSidebarNavDropdown",
        //   name: "Job Applicant",
        //   icon: "cil-layers",
        //   lang: "en",
        //   items: [
        //     {
        //       name: "Applicant",
        //       to: "/pelamarKerja",
        //     },
        //     {
        //       name: "Applicant Documents",
        //       to: "/kelengkapanPelamar",
        //     },
        //   ],
        // },

        {
          _name: "CSidebarNavDropdown",
          name: "Pelamar Kerja",
          icon: "cil-layers",
          lang: "id",
          role: ["admin rekrutment", "superadmin", "admin spv"],
          items: [
            {
              name: "Pelamar",
              to: "/pelamarKerja",
            },
            {
              name: "Kelengkapan Pelamar",
              to: "/kelengkapanPelamar",
            },
          ],
        },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Applicant Selection Stage",
        //   to: "/tahapSeleksiPelamar",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        {
          _name: "CSidebarNavItem",
          name: "Tahap Seleksi Pelamar",
          to: "/tahapSeleksiPelamar",
          icon: "cil-layers",
          lang: "id",
          role: ["admin rekrutment", "superadmin", "admin spv"],
        },

        // {
        //   _name: "CSidebarNavDropdown",
        //   name: "Employee",
        //   icon: "cil-layers",
        //   lang: "en",
        //   items: [
        //     {
        //       name: "Employee",
        //       to: "/karyawan",
        //     },

        //     {
        //       name: "Letter of Agrement",
        //       to: "/perjanjianKerja",
        //     },
        //     {
        //       name: "Work Order",
        //       to: "/kontrak",
        //     },

        //     {
        //       name: "Letter of Approval",
        //       to: "/suratPersutujuan",
        //     },
        //     {
        //       name: "Mutations",
        //       to: "/mutasi",
        //     },
        //   ],
        // },

        {
          _name: "CSidebarNavDropdown",
          name: "Karyawan",
          icon: "cil-layers",
          lang: "id",
          role: ["superadmin", "admin hrd", "admin spv"],
          items: [
            {
              name: "Karyawan",
              to: "/karyawan",
            },

            {
              name: "Perjanjian Kerja",
              to: "/perjanjianKerja",
            },
            {
              name: "Perintah Kerja",
              to: "/kontrak",
            },
            {
              name: "Persetujuan Kerja",
              to: "/suratPersetujuan",
            },
            {
              name: "Mutasi",
              to: "/mutasi",
            },
          ],
        },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Work Accident",
        //   to: "/kecelakaanKerja",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        {
          _name: "CSidebarNavItem",
          name: "Kecelakaan Kerja",
          to: "/kecelakaanKerja",
          icon: "cil-layers",
          lang: "id",
          role: [ "superadmin", "admin hrd", "admin spv"],
        },

        {
          _name: "CSidebarNavDropdown",
          name: "Laporan",
          icon: "cil-layers",
          lang: "id",
          role: ["superadmin", "admin hrd", "admin spv"],
          items: [
            // {
            //   name: "Rekap Absensi",
            //   to: "/rekapAbsensi",
            // },

            {
              name: "Proyeksi Gaji",
              to: "/laporanGaji",
            },
            {
              name: "Rekap Gaji",
              to: "/rekapGaji",
            },
            {
              name: "Total Potongan",
              to: "/totalPotongan",
            },
            {
              name: "THR",
              to: "/THR",
            },
            {
              name: "Laporan Lembur",
              to: "/lembur",
            },
          ],
        },

        // {
        //   _name: "CSidebarNavDropdown",
        //   name: "Report",
        //   icon: "cil-layers",
        //   lang: "en",
        //   items: [
        //     // {
        //     //   name: "Absent Report",
        //     //   to: "/rekapAbsensi",
        //     // },

        //     {
        //       name: "Salary Projection",
        //       to: "/laporanGaji",
        //     },
        //     {
        //       name: "Salary Recap",
        //       to: "/rekapGaji",
        //     },
        //     {
        //       name: "Total Cut",
        //       to: "/totalPotongan",
        //     },
        //     {
        //       name: "Holiday Benefit",
        //       to: "/THR",
        //     },
        //     {
        //       name: "Overtime Report",
        //       to: "/lembur",
        //     },
        //   ],
        // },

        {
          _name: "CSidebarNavDropdown",
          name: "Absensi",
          icon: "cil-layers",
          lang: "id",
          role: ["superadmin", "admin hrd", "admin spv"],
          items: [
            {
              name: "Rekap Absensi",
              to: "/rekapAbsensi",
            },

            {
              name: "Pengajuan Cuti",
              to: "/pengajuanCuti",
            },
          ],
        },

        // {
        //   _name: "CSidebarNavDropdown",
        //   name: "Report",
        //   icon: "cil-layers",
        //   lang: "en",
        //   items: [
        //     {
        //       name: "Absent Report",
        //       to: "/rekapAbsensi",
        //     },

        //     {
        //       name: "Paid Leave",
        //       to: "/pengajuanCuti",
        //     },
        //   ],
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Attendance Summary",
        //   to: "/rekapAbsensi",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Rekap Absensi",
        //   to: "/rekapAbsensi",
        //   icon: "cil-layers",
        //   lang: "id",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Proyeksi Gaji",
        //   to: "/laporanGaji",
        //   icon: "cil-layers",
        //   lang: "id",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Salary Projection",
        //   to: "/laporanGaji",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Rekap Gaji",
        //   to: "/rekapGaji",
        //   icon: "cil-layers",
        //   lang: "id",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Salary Recap",
        //   to: "/rekapGaji",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Riwayat Karyawan",
        //   to: "/History",
        //   icon: "cil-layers",
        //   lang: "id",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Employee Histori",
        //   to: "/Histori",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Total Potongan",
        //   to: "/totalPotongan",
        //   icon: "cil-layers",
        //   lang: "id",
        // },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Total Cuts",
        //   to: "/totalPotongan",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        {
          _name: "CSidebarNavItem",
          name: "Surat Konfirmasi",
          to: "/suratKonfirmasi",
          icon: "cil-layers",
          lang: "id",
          role: ["superadmin", "admin hrd", "admin spv"],
        },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Confirmation Letters",
        //   to: "/suratKonfirmasi",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        {
          _name: "CSidebarNavItem",
          name: "Penilaian Karyawan",
          to: "/assesmentKaryawan",
          icon: "cil-layers",
          lang: "id",
          role: ["superadmin", "admin hrd", "admin spv"],
        },

        // {
        //   _name: "CSidebarNavItem",
        //   name: "Employee Assesment",
        //   to: "/assesmentKaryawan",
        //   icon: "cil-layers",
        //   lang: "en",
        // },

        {
          _name: "CSidebarNavDivider",
          _class: "m-2",
        },
      ],
    };
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
    roles() {
      return localStorage.getItem("role");
    },
    computedSideBarItems() {
      return this.AllSideBarItem.filter((item) => {
        return !item.role || item.role.includes(this.roles);
      });
    },
    // sortedSidebarItems() {
    //   this.AllSideBarItem.map((item) => {
    //     let x = item.items;
    //     if (x) {
    //       x.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    //     }
    //     return item;
    //   });
    //   let z = this.AllSideBarItem.sort((a, b) =>
    //     a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    //   );
    //   console.log(z, "zzz");
    //   return z;
    // },
    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.computedSideBarItems,
        },
      ];
    },
  },
};
</script>
