<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"> Dashboard </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/user" exact> User </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink> Settings </CHeaderNavLink>
      </CHeaderNavItem> -->
    </CHeaderNav>
    <CHeaderNav>
      <!-- <CHeaderNavItem class="px-1 c-d-legacy-none">
        <button
          v-if="$store.state.lang == 'id'"
          @click="() => $store.commit('set_lang', 'en')"
          class="c-header-nav-btn"
        >
          id
        </button>
        <button
          v-else
          @click="() => $store.commit('set_lang', 'id')"
          class="c-header-nav-btn"
        >
          en
        </button>
      </CHeaderNavItem> -->
      <CHeaderNavItem class="px-1 c-d-legacy-none">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-1 c-d-legacy-none">
        <!-- <CHeaderNavLink to="/detailNotifikasi">
          <CIcon name="cil-bell" />
          <CBadge shape="pill" color="danger">{{ itemsCount }}</CBadge>
        </CHeaderNavLink> -->
      </CHeaderNavItem>
      <!-- <TheHeaderDropdownNotif /> -->
      <!-- <TheHeaderDropdownTasks /> -->
      <!-- <TheHeaderDropdownMssgs /> -->
      <TheHeaderDropdownAccnt />
      <CHeaderNavItem class="px-3">
        <!-- <button
          class="c-header-nav-btn"
          @click="$store.commit('toggle', 'asideShow')"
        >
          <CIcon size="lg" name="cil-applications-settings" class="mr-2" />
        </button> -->
      </CHeaderNavItem>
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      itemsCount: 0,
    };
  },
  created() {
    // this.getNotif();
    // this.hitung(15)
  },
  methods: {
    getNotif() {
      axios
        .get(ipBackend + "OP/listByStatusOP/0")
        .then((res) => {
          console.log(res);
          this.itemsCount = res.data.data.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
